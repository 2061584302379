.App {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    .icon {
      height: 100px;
      width: 0100px;
    }
    color: gray;
  }
  